import React, { useState, useEffect, useRef, forwardRef } from 'react';

import metrics, { metricKeys } from './metricDefinitions';
import { useOutsideBlur } from './helper/utils';

import SwingsDistribution from './vzSwingsDistribution';
import Dropdown from './Dropdown';

import './fa/css/all.css';
import './css/App.css';
import './css/Metrics.css';

interface ifDistribution {
    filters:any;
    visible:boolean;
    viewType?:string;
    refreshKey?:number;
    updateHook?:Function;
    subtitle?:string;
}
const Distribution = ({ filters, visible, viewType="global", refreshKey, updateHook, subtitle }:ifDistribution) => {
    const [metricSelected, setMetricSelected] = useState<string>("swing_speed");
    const [metricsList, setMetricsList] = useState<any[]>(
        localStorage.getItem(`${viewType}Distribution`)
            ? JSON.parse(localStorage.getItem(`${viewType}Distribution`) || '[]')
            : metricKeys.filter((metricKey:string) => metrics[metricKey].dailySplits)
    );

    const writeMetricsList = (list:string[]) => {
        localStorage.setItem(`${viewType}Distribution`, JSON.stringify(list));
        setMetricsList(list);

        if(updateHook) {
            updateHook();
        }
    }

    return (
        <div className={`route-container ${visible ? "show" : "hide"}`}>
            <DesktopTabs configuredMetrics={metricsList} setConfiguredMetrics={writeMetricsList} selected={metricSelected} setSelected={setMetricSelected} metrics={metrics} />
            <MobileTabs configuredMetrics={metricsList} setConfiguredMetrics={writeMetricsList} selected={metricSelected} setSelected={setMetricSelected} metrics={metrics} />
            {
                metricsList.map((metricKey:string) => metrics[metricKey]).map((metric:any) => {
                    return <SwingsDistribution
                        key={`daily-stats-${metric.key}`}
                        {...filters}
                        metric={metric}
                        selected={metric.key == metricSelected ? true : false}
                        visible={visible}
                        subtitle={subtitle}
                    />
                })
            }
        </div>
    );
}

interface ifDesktopTabs {
    configuredMetrics:string[];
    setConfiguredMetrics:Function;
    selected:string;
    setSelected:any;
    metrics:any;
}
const DesktopTabs = ({ configuredMetrics, setConfiguredMetrics, selected, setSelected, metrics }:ifDesktopTabs) => {
    const [addMenuVisible, setAddMenuVisible] = useState<boolean>(false);
    const [add, setAdd] = useState<string|null>(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [error, setError] = useState<string|null>(null);
    const menuRef = useRef(null);

    useOutsideBlur(menuRef, () => { setAddMenuVisible(false);});

    return (
        <div className="graph-tabs graph-tabs-desktop">
            {
                configuredMetrics.map((metricKey:string) => {
                    return (
                        <div
                            key={`daily-stats-tab-${metricKey}`}
                            className={`graph-tab ${metricKey == selected ? "selected" : ""}`}
                            onClick={() => {
                                setSelected(metricKey);
                            }}
                        >
                            {metrics[metricKey].label}
                            <i className={`fa-solid fa-trash-can ${!editMode ? "hide" : ""}`} onClick={(e) => {
                                setConfiguredMetrics(configuredMetrics.filter((_metricKey:string) => _metricKey !== metricKey));
                                e.stopPropagation();
                            }} />
                        </div>
                    )
                })
            }
            <div
                className={`graph-tab graph-tab-add ${addMenuVisible ? "open" : ""}`}
                onClick={() => {
                    setAddMenuVisible(true);
                }}
                ref={menuRef}
            >
                <i className="fa-solid fa-plus" />
                <div className={`graph-tab-add-menu desktop ${addMenuVisible ? "show" : "hide"}`}>
                    <div className={`error ${error ? "show" : "hide"}`}>
                        <i className="fa-solid fa-circle-exclamation" /> {error}
                    </div>
                    <Dropdown
                        options={Object.keys(metrics).map((metricKey:string) => {
                            return {
                                "key" : metrics[metricKey].key,
                                "value" : metrics[metricKey].label
                            }
                        })}
                        selected={add}
                        setSelected={setAdd}
                        placeholder="Metric"
                        className="dropdown"
                    />
                    <div className="button-group right small">
                        <button className="primary" onClick={(e) => {
                            if(!add) {
                                setError("Metric is required");
                            } else {
                                setError(null);
                                setAddMenuVisible(false);
                                setConfiguredMetrics([
                                    ...configuredMetrics,
                                    add
                                ]);
                                setSelected(add);
                                setAdd(null);
                            }
                            e.stopPropagation();
                        }}>Create Chart</button>
                        <button onClick={(e) => {
                            setAddMenuVisible(false);
                            setAdd(null);
                            setError(null);
                            e.stopPropagation();
                        }}><i className="fa-solid fa-xmark" /></button>
                    </div>
                </div>
            </div>
            <div
                className={`graph-tab graph-tab-edit`}
                onClick={() => {
                    setEditMode(editMode ? false : true);
                }}
            >
                <i className={`fa-solid fa-${editMode ? "check" : "pen-to-square"}`} />
            </div>
        </div>
    );
}

interface ifMobileTabs {
    configuredMetrics:string[];
    setConfiguredMetrics:Function;
    selected:string;
    setSelected:any;
    metrics:any
}
const MobileTabs = ({ configuredMetrics, setConfiguredMetrics, selected, setSelected, metrics }:ifMobileTabs) => {
    const [metricMenuOpen, setMetricMenuOpen] = useState<boolean>(false);
    const [addMenuVisible, setAddMenuVisible] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [add, setAdd] = useState<string|null>(null);
    const [error, setError] = useState<string|null>(null);
    const addMenuRef = useRef(null);
    const tabsMenuRef = useRef(null);

    useOutsideBlur(tabsMenuRef, () => { setMetricMenuOpen(false);});

    return (
        <div className="graph-tabs graph-tabs-mobile" ref={tabsMenuRef}>
            <div className="graph-tab dropdown" onClick={() => {
                setMetricMenuOpen(metricMenuOpen ? false : true);
            }}>{metrics[selected].label}<i className={`fa-solid fa-caret-${metricMenuOpen ? "up" : "down"}`} /></div>
            <div className={`graph-tabs-menu ${metricMenuOpen ? "open" : "closed"}`}>
                {
                    configuredMetrics.map((metricKey:string) => metrics[metricKey]).map((metric:any) => {
                        return (
                            <div
                                key={`daily-stats-tab-${metric.key}`}
                                className={`graph-tab ${metric.key == selected ? "selected" : ""}`}
                                onClick={() => {
                                    setSelected(metric.key);
                                    setMetricMenuOpen(false);
                                }}
                            >
                                {metric.label}
                                <span className={`fa-solid fa-trash-can delete ${editMode ? "" : "hide"}`} onClick={(e) => {
                                    setConfiguredMetrics(configuredMetrics.filter((metricKey:string) => metricKey !== metric.key));
                                    e.stopPropagation();
                                }} />
                                <i className="fa-solid fa-caret-down no-display" />
                            </div>
                        )
                    })
                }
                <div
                    className={`graph-tab graph-tab-add ${addMenuVisible ? "open" : ""}`}
                    onClick={() => {
                        setAddMenuVisible(true);
                    }}
                    ref={addMenuRef}
                >
                    <span className="fa-solid fa-plus" /> Create Chart
                    <div className={`graph-tab-add-menu mobile ${addMenuVisible ? "show" : "hide"}`}>
                        <div className={`error ${error ? "show" : "hide"}`}>
                            <i className="fa-solid fa-circle-exclamation" /> {error}
                        </div>
                        <Dropdown
                            options={Object.keys(metrics).map((metricKey:string) => {
                                return {
                                    "key" : metrics[metricKey].key,
                                    "value" : metrics[metricKey].label
                                }
                            })}
                            selected={add}
                            setSelected={setAdd}
                            placeholder="Metric"
                            className="dropdown"
                        />
                        <div className="button-group right small">
                            <button className="primary" onClick={(e) => {
                                if(!add) {
                                    setError("Metric required");
                                } else {
                                    setError(null);
                                    setAddMenuVisible(false);
                                    setMetricMenuOpen(false);
                                    setConfiguredMetrics([
                                        ...configuredMetrics,
                                        add
                                    ]);
                                    setAdd(null);
                                    setSelected(add);
                                }
                                e.stopPropagation();
                            }}>Create Chart</button>
                            <button onClick={(e) => {
                                setAddMenuVisible(false);
                                setAdd(null);
                                setError(null);
                                e.stopPropagation();
                            }}><i className="fa-solid fa-xmark" /></button>
                        </div>
                    </div>
                </div>
                <div
                    className={`graph-tab graph-tab-add}`}
                    onClick={() => {
                        setEditMode(editMode ? false : true);
                    }}
                    ref={addMenuRef}
                >
                    <span className={`fa-solid fa-${editMode ? "check" : "pen-to-square"}`} /> {editMode ? "Done" : "Remove Charts"}
                </div>
            </div>
        </div>
    );
}

export default Distribution;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useSession } from './hooks/session';
import apiRequest from './helper/ws';
import { setAccountList as setStateAccountList } from './reducers/appSlice';

const AccountLink = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [accountList, setAccountList] = useState<any[]>([]);
    const [refreshAccountList, setRefreshAccountList] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const accounts:any[] = useSelector((state:any) => state.app.accountList);
    const accessToken:string = useSelector((state:any) => state.app.accessToken);
    const { getAccessToken, getAccessTokenForce, checkPermission } = useSession();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!refreshAccountList) {
            return;
        }

        getAccountList(false);
    }, [refreshAccountList]);

    const getAccountList = async (noCache:boolean) => {
        const accounts:any[] = await apiRequest({
            "path" : `/user/accounts`,
            "method" : "GET",
            "accessToken" : await getAccessToken()
        });

        dispatch(setStateAccountList(accounts));

        setAccountList(accounts);
        setRefreshAccountList(false);
    }

    const handleLinkAccount = async () => {
        if(!email || !password) {
            setError("");
            return;
        } else if(!email.includes("@")) {
            setError("Please enter a valid email address");
            return;
        }

        setLoading(true);

        const linkResponse:any = await apiRequest({
            "path" : "/user/blast/link",
            "method" : "POST",
            "data" : {
                "email" : email,
                "password" : password
            },
            "accessToken" : await getAccessToken()
        });

        setLoading(false);

        if(linkResponse.blast_id) {
            getAccessTokenForce();
            getAccountList(true);
            setEmail("");
            setPassword("");
            setError("");
        } else {
            setError("Account linking failed -- Please check the email and password");
        }
    }

    const handleUnlinkAccount = async (blastId:number) => {
        setLoading(true);

        await apiRequest({
            "path" : `/user/blast/unlink/${blastId}`,
            "method" : "POST",
            "accessToken" : await getAccessToken()
        });

        getAccessTokenForce();
        getAccountList(true);
        //setRefreshAccountList(true);
        setLoading(false);
    }

    const handleClose = async () => {
        if(accountList.length) {
            navigate("/");
        } else {
            setError("");
            setEmail("");
            setPassword("");
        }
    }

    const LinkedAccounts = () => {
        if(!accountList.length) {
            return <></>
        }

        return <div className="accounts-container">
            <div className="account">
                <div className="account-value flex header">Blast Email</div>
                <div className="account-value player-name header">Player Name</div>
                <div className="account-value player-initials header">Initials</div>
                <div className="account-value player-id header">ID</div>
                <div className="account-value buttons header">&nbsp;</div>
            </div>
            {accountList.map((account:any) => {
                return <div className="account" key={account.blast_id}>
                    <div className="account-value flex">{account.email}</div>
                    <div className="account-value player-name">{account.full_name}</div>
                    <div className="account-value player-initials">{account.initials}</div>
                    <div className="account-value player-id">{account.blast_id}</div>
                    <div className="account-value buttons right">
                        <button onClick={() => {
                            handleUnlinkAccount(account.blast_id);
                        }}>
                            <i className="fa-solid fa-unlink" />
                        </button>
                    </div>
                </div>
            })}
        </div>
    }

    const Error = () => {
        if(!error) {
            return <></>
        }

        return <div className="link-error-container">
            <span>{error}</span>
        </div>
    }

    if(loading) {
        return <div className={`app-loader`}>
            <div className="loader-container">
                <div className="loading-text">Please Wait</div>
                <i className="fa-solid fa-spinner rotate" />
            </div>
        </div>
    } else if(!checkPermission("link:account")) {
        return <></>
    }

    return <div className="link-container">
        <div className="title-content">
            <h1>Link a Blast Motion Account</h1>
            <Error />
        </div>
        <div className="form-container">
            <input type="text" onChange={(event) => {
                setEmail(event.target.value);
            }} id="blast-email" placeholder="Email Address" defaultValue={email} />
            <input type="password" onChange={(event) => {
                setPassword(event.target.value);
            }} id="blast-password" placeholder="Password" defaultValue={password} />
            <div className="button-group right">
                <button className="primary" onClick={() => {
                    handleLinkAccount();
                }}>
                    <i className="fa-solid fa-link" /> Link Account
                </button>
                {
                    accountList.length > 0 &&
                        <button onClick={handleClose}>
                            <i className="fa-solid fa-xmark" />
                        </button>
                    
                }
            </div>
        </div>
        <LinkedAccounts />
    </div>
}

export default AccountLink;

import { Auth0Provider, AppState, useAuth0 } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithNavigate = ({
    children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
            clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
            authorizationParams={{
                redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
};
import React, { useState, useEffect, useRef, forwardRef, HTMLProps } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker'

import useSession from './hooks/session';
import apiRequest from './helper/ws';
import { useOutsideBlur } from './helper/utils';
import Dropdown from './Dropdown';
import { setAccessToken, setSelectedAccount } from './reducers/appSlice';

import './css/Header.css';

interface ifHeader {
    route:string;
    refresh:Function;
    selectedAccount:any;
    setSelectedAccount:Function;
    selectedAccountProfile:any;
}
const Header = ({ route, refresh, selectedAccountProfile }:ifHeader) => {
    const selectedAccount:any = useSelector((state:any) => state.app.selectedAccount) ?? {};
    const [showAddSwings, setShowAddSwings] = useState<boolean>(false);
    const [showSelectAccount, setShowSelectAccount] = useState<boolean>(false);
    const [addSwingsAccount, setAddSwingsAccount] = useState<any>(selectedAccount ?? {});
    const [showMainMenu, setShowMainMenu] = useState<boolean>(false);
    const [dateStart, setDateStart] = useState<string|null>(DateTime.now().toISODate());
    const [dateEnd, setDateEnd] = useState<string|null>(DateTime.now().toISODate());
    const [loading, setLoading] = useState<boolean>(false);
    const [addSwingsResponse, setAddSwingsResponse] = useState<any|null>(null);
    const [replaceSwings, setReplaceSwings] = useState<boolean>(false);
    const [downloadSwingsError, setDownloadSwingsError] = useState<String|null>(null);
    const { getAccessToken, checkPermission } = useSession();
    const mainMenuRef = useRef(null);
    const addSwingsMenuRef = useRef(null);
    const selectAccountMenuRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accounts:any[] = useSelector((state:any) => state.app.accountList);
    const { logout } = useAuth0();
    const DateStartButton = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>((options:any, ref) => (
        <button className="date-select" onClick={options.onClick} ref={ref}>
          {options.value}
        </button>
    ));
    const DateEndButton = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>((options:any, ref) => (
        <button className="date-select" onClick={options.onClick} ref={ref}>
          {options.value}
        </button>
    ));

    useEffect(() => {
        setShowSelectAccount(false);
    }, [selectedAccount]);

    useEffect(() => {
        if(showAddSwings) {
            setAddSwingsAccount(selectedAccount ?? {});
        }

        setDownloadSwingsError(null);
    }, [showAddSwings]);

    useEffect(() => {
        if(accounts.length === 1) {
            //setTimeout(() => {
                setAppAccount(accounts[0].blast_id);
            //}, 1000);
        }
    }, [accounts.length]);


    const headerItemClass = (item:string) => {
        if(route === item) {
            return "header-item selected";
        }

        return "header-item";
    }

    const downloadSwings = async () => {
        if(!addSwingsAccount.blast_id) {
            setDownloadSwingsError("Please select a batter");
            return;
        }

        setShowAddSwings(false);
        setLoading(true);

        const params:any = {
            "account" : addSwingsAccount.blast_id
        };

        if(dateStart === dateEnd) {
            params.date = dateStart;
        } else {
            params.date_start = dateStart;
            params.date_end = dateEnd;
        }

        if(replaceSwings) {
            params.replace = true;
        }

        const swingsResponse = await apiRequest({
            "method" : "POST",
            "path" : `/swings`,
            "params" : params,
            "accessToken" : await getAccessToken()
        });

        setTimeout(() => {
            refresh();
        }, 1500);
        setAddSwingsResponse(swingsResponse);
        setLoading(false);
    }

    const setAppAccount = (account:any) => {
        const _selected = accounts.find((_account:any) => account === _account.blast_id);

        dispatch(setSelectedAccount(_selected ?? {}));
    }

    const AddSwingsResult = () => {
        if(!addSwingsResponse) {
            return <></>
        }

        if(addSwingsResponse.background) {
            return (
                <div className="swings-response-container left">
                    <div className="swing-response">Downloading <b>{addSwingsResponse.swingsTotal}</b> Swings. . .</div>
                    <div className="swing-explanation">Swing caching will be performed in the background.  You may close this dialog and check for updated results momentarily.</div>
                    <div className="button-group right">
                        <button className="primary" onClick={() => {
                            setAddSwingsResponse(null);
                        }}>
                            Done
                        </button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="swings-response-container">
                    <div className="swing-response">Total Swings: <b>{addSwingsResponse.swingsTotal}</b></div>
                    <div className="swing-response">Inserted: <b>{addSwingsResponse.swingsInserted}</b></div>
                    <div className="swing-response">Skipped: <b>{addSwingsResponse.swingsSkipped}</b></div>
                    <div className="swing-response">Replaced: <b>{addSwingsResponse.swingsReplaced}</b></div>
                    <div className="swing-response">Ignored: <b>{addSwingsResponse.swingsIgnored}</b></div>
                    <div className="button-group right">
                        <button className="primary" onClick={() => {
                            setAddSwingsResponse(null);
                        }}>
                            Done
                        </button>
                    </div>
                </div>
            )
        }
    }

    const AccountButton = () => {
        if(selectedAccount.blast_id) {
            return <span className="selected-account">{selectedAccount.initials}</span>
        }

        return <i className="fa-solid fa-user" />
    }

    const AccountDropdown = () => {
        if(!accounts.length) {
            return <></>
        }

        //return <div className={`header-menu ${showSelectAccount ? "show" : "hide"}`}>
            return <Dropdown
                options={[
                    {
                        "key" : null,
                        "value" : "All Players"
                    },
                    ...accounts.map((account:any) => {
                        return {
                            "key" : account.blast_id,
                            "value" : account.name
                        }
                    })
                ]}
                selected={selectedAccount.blast_id}
                setSelected={setAppAccount}
                emptyPlaceholder="Select a Player"
                className="dropdown select-account"
            />
        //</div>
    }

    const handleLogout = async () => {
        dispatch(setAccessToken(""));

        logout({
            logoutParams : {
                returnTo: window.location.origin
            }
        });
    }

    useOutsideBlur(mainMenuRef, () => {
        setShowMainMenu(false);
    });

    useOutsideBlur(addSwingsMenuRef, () => {
        setShowAddSwings(false);
    });

    useOutsideBlur(selectAccountMenuRef, () => {
        setShowSelectAccount(false);
    });

    return (
        <>
            <div id="app-header" className="header">
                <div ref={mainMenuRef} className="header-main">
                    <button onClick={() => {
                        setShowMainMenu(showMainMenu ? false : true);
                    }}>
                        <i className="fa-solid fa-bars" />
                    </button>
                    <div className={`header-nav ${showMainMenu ? "header-nav-show" : "header-nav-hide"}`}>
                        <div className={headerItemClass("time-series")} onClick={() => {
                            setShowMainMenu(false);
                            navigate("/time-series");
                        }}>Time Series</div>
                        <div className={headerItemClass("dual-series")} onClick={() => {
                            setShowMainMenu(false);
                            navigate("/dual-series");
                        }}>Dual Series</div>
                        <div className={headerItemClass("daily-splits")} onClick={() => {
                            setShowMainMenu(false);
                            navigate("/daily-splits");
                        }}>Daily Splits</div>
                        <div className={headerItemClass("equipment-splits")} onClick={() => {
                            setShowMainMenu(false);
                            navigate("/equipment-splits");
                        }}>Bat Splits</div>
                        <div className={headerItemClass("distribution")} onClick={() => {
                            setShowMainMenu(false);
                            navigate("/distribution");
                        }}>Distribution</div>
                        <div className={headerItemClass("swing-data")} onClick={() => {
                            setShowMainMenu(false);
                            navigate("/swing-data");
                        }}>Swing Data</div>
                    </div>
                </div>
                <div className="header-fill" />
                <div className="header-icon" ref={selectAccountMenuRef}>
                    <button onClick={() => {
                        setShowSelectAccount(showSelectAccount ? false : true);
                        setShowAddSwings(false);
                    }}>
                        <AccountButton />
                    </button>
                    <div className={`header-menu profile ${showSelectAccount ? "show" : "hide"}`}>
                        <div className="profile-info">
                            <span className="account-name">{accounts.length === 1 ? selectedAccount.name : <AccountDropdown />}</span>
                            {selectedAccount.blast_id && <div>
                                <span className="account-email">{selectedAccount.email}</span>
                                <span className="account-detail">{Number(selectedAccountProfile.swing_count).toLocaleString()} Lifetime Swings</span>
                                <span className="account-detail">{DateTime.fromISO(selectedAccountProfile.date_first).toLocal().toFormat('MM/dd/yy')} - {DateTime.fromISO(selectedAccountProfile.date_last).toLocal().toFormat('MM/dd/yy')}</span>
                            </div>}
                            {checkPermission("link:account") && <div className="profile-link">
                                <a onClick={() => {
                                    navigate("/link");
                                }}>Link Another Account</a>
                            </div>}
                        </div>
                    </div>
                </div>
                {checkPermission("cache:swings") && <div className="header-icon" ref={addSwingsMenuRef}>
                    <button onClick={() => {
                        setShowAddSwings(showAddSwings ? false : true);
                        setShowSelectAccount(false);
                    }}>
                        <i className="fa-solid fa-download" />
                    </button>
                    <div className={`header-menu ${showAddSwings ? "show" : "hide"}`}>
                        <div className={`swings-add-error ${downloadSwingsError ? "show" : "hide"}`}>{downloadSwingsError}</div>
                        {accounts.length > 1 && <Dropdown
                            options={accounts.map((account:any) => {
                                return {
                                    "key" : account.blast_id,
                                    "value" : account.name
                                }
                            })}
                            selected={addSwingsAccount.blast_id}
                            setSelected={(account:any) => {
                                setAddSwingsAccount(accounts.find((_account:any) => _account.blast_id === account));
                            }}
                            emptyPlaceholder="Select a Player"
                            className="dropdown swings-add"
                        />}
                        Start: <DatePicker
                            onChange={(date) => {
                                setDateStart(date ? DateTime.fromJSDate(date).toFormat("yyyy-MM-dd") : null);
                                setDateEnd(date ? DateTime.fromJSDate(date).toFormat("yyyy-MM-dd") : null);
                            }}
                            selected={dateStart ? DateTime.fromISO(dateStart).toJSDate() : null}
                            maxDate={DateTime.now().toJSDate()}
                            customInput={<DateStartButton />}
                            wrapperClassName="filter-datepicker"
                            calendarClassName="calendar-picker"
                            popperPlacement="top-start"
                        />
                        End: <DatePicker
                            onChange={(date) => {
                                setDateEnd(date ? DateTime.fromJSDate(date).toFormat("yyyy-MM-dd") : null);
                            }}
                            selected={dateEnd ? DateTime.fromISO(dateEnd).toJSDate() : null}
                            maxDate={DateTime.now().toJSDate()}
                            customInput={<DateEndButton />}
                            wrapperClassName="addswings-datepicker"
                            calendarClassName="calendar-picker"
                            popperPlacement="top-start"
                        />
                        <label htmlFor="replace" id="lol">Replace Existing</label><input id="replace" type="checkbox" defaultChecked={replaceSwings} onClick={() => {
                            setReplaceSwings(replaceSwings ? false : true);
                        }} />
                        <div className="button-group right">
                            <button onClick={() => {
                                setShowAddSwings(false);
                            }}><i className="fa-solid fa-xmark" /></button>
                            <button className="primary" onClick={() => {
                                downloadSwings();
                            }}>Add Swings</button>
                        </div>
                    </div>
                </div>}
                {/*checkPermission("link:account") && <div className="header-icon">
                    <button onClick={() => {
                        navigate("/link");
                    }}>
                        <i className="fa-solid fa-link" />
                    </button>
                </div>*/}
                <div className="header-icon">
                    <button onClick={() => { refresh(); }}>
                        <i className="fa-solid fa-refresh" />
                    </button>
                </div>
                <div className="header-icon">
                    <button onClick={handleLogout}>
                        <i className="fa-solid fa-right-from-bracket" />
                    </button>
                </div>
            </div>
            <div className={`app-loader ${!loading ? "hide" : ""}`}>
                <div className="loader-container">
                    <div className="loading-text">Downloading Swings</div>
                    <i className="fa-solid fa-spinner rotate" />
                </div>
            </div>
            <div className={`app-loader ${!addSwingsResponse ? "hide" : ""}`}>
                <div className="loader-container">
                    <AddSwingsResult />
                </div>
            </div>
        </>
    );
}

export default Header;
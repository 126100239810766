import config from './helper/config';

const __defaultMetricDef = {
    "label" : "",
    "shortLabel" : "",
    "key" : "",
    "yAxis" : {},
    "field" : "",
    "unit" : "",
    "dateScatter" : false,
    "dualScatter" : "",
    "statSummary" : false,
    "dailySplits" : false,
    "dataView" : false,
    "colors" : {
        "equipment" : config.graph.colors1,
        "environment" : config.graph.colors2
    }
}

const setMetricDef = (metricKey:string, metricDef:any) => {
    return {
        [metricKey] : {
            ...__defaultMetricDef,
            ...metricDef
        }
    }
}

const metrics:any = {
    ...setMetricDef("swing_speed", {
        "label" : "Bat Speed",
        "shortLabel" : "Bat Speed",
        "key" : "swing_speed",
        "field" : "metrics.swing_speed.value",
        "unit" : "mph",
        "dateScatter" : true,
        "dualScatter" : "bat_path_angle",
        "statSummary" : true,
        "dailySplits" : true,
        "dataView" : true,
        "yAxis" : {
            "label" : "Bat Speed (mph)"
        },
        "colors" : {
            "equipment" : config.graph.colors1,
            "environment" : config.graph.colors2,
            "account" : config.graph.colors3
        }
    }),
    ...setMetricDef("bat_path_angle", {
        "label" : "Attack Angle",
        "shortLabel" : "Attack Angle",
        "key" : "bat_path_angle",
        "field" : "metrics.bat_path_angle.value",
        "unit" : "°",
        "dateScatter" : true,
        "statSummary" : true,
        "dataView" : true,
        "yAxis" : {
            "label" : "Attack Angle",
            "bands" : [{
                from: 4, // Starting value of the band
                to: 12,  // Ending value of the band
                color: 'rgba(68, 170, 213, 0.2)', // Color of the band
                label: {
                    text: 'Target Attack Angle', // Label for the band
                    align: 'center', // Label alignment
                    style: {
                        color: '#606060' // Label text style
                    }
                }
            }]
        },
        "colors" : {
            "equipment" : config.graph.colors3,
            "environment" : config.graph.colors4,
            "account" : config.graph.colors5
        },
    }),
    ...setMetricDef("planar_efficiency", {
        "label" : "Planar Efficiency",
        "shortLabel" : "P Efficiency",
        "key" : "planar_efficiency",
        "field" : "metrics.planar_efficiency.value",
        "unit" : "%",
        "dualScatter" : "bat_path_angle",
        "statSummary" : true,
        "yAxis" : {
            "label" : "Planar Efficiency",
            "max" : 100
        },
        "colors" : {
            "equipment" : config.graph.colors5,
            "environment" : config.graph.colors6,
            "account" : config.graph.colors7
        }
    }),
    ...setMetricDef("rotational_acceleration", {
        "label" : "Rotational Acceleration",
        "shortLabel" : "Acceleration",
        "key" : "rotational_acceleration",
        "field" : "metrics.rotational_acceleration.value",
        "unit" : "",
        "statSummary" : true,
        "dataView" : true,
        "yAxis" : {
            "label" : "Rotational Acceleration"
        },
        "colors" : {
            "equipment" : config.graph.colors7,
            "environment" : config.graph.colors8,
            "account" : config.graph.colors1
        }
    }),
    ...setMetricDef("peak_hand_speed", {
        "label" : "Hand Speed",
        "shortLabel" : "Hand Speed",
        "key" : "peak_hand_speed",
        "field" : "metrics.peak_hand_speed.value",
        "unit" : "mph",
        "statSummary" : true,
        "dataView" : true,
        "yAxis" : {
            "label" : "Hand Speed (mph)"
        },
        "colors" : {
            "equipment" : config.graph.colors2,
            "environment" : config.graph.colors5,
            "account" : config.graph.colors8
        }
    }),
    ...setMetricDef("blast_factor_2", {
        "label" : "Blast Factor",
        "shortLabel" : "Blast Factor",
        "key" : "blast_factor_2",
        "field" : "metrics.blast_factor_2.value",
        "yAxis" : {
            "label" : "Blast Factor"
        },
        "colors" : {
            "equipment" : config.graph.colors6,
            "environment" : config.graph.colors1,
            "account" : config.graph.colors4
        }
    }),
    ...setMetricDef("body_rotation", {
        "label" : "Body Rotation",
        "shortLabel" : "Body Rotation",
        "key" : "body_rotation",
        "field" : "metrics.body_rotation.value",
        "yAxis" : {
            "label" : "Body Rotation"
        },
        "colors" : {
            "equipment" : config.graph.colors8,
            "environment" : config.graph.colors3,
            "account" : config.graph.colors6
        }
    }),
    ...setMetricDef("body_tilt_angle", {
        "label" : "Body Tilt",
        "shortLabel" : "Body Tilt",
        "key" : "body_tilt_angle",
        "field" : "metrics.body_tilt_angle.value",
        "unit" : "°",
        "yAxis" : {
            "label" : "Body Tilt Angle (°)"
        },
        "colors" : {
            "equipment" : config.graph.colors5,
            "environment" : config.graph.colors6,
            "account" : config.graph.colors2
        }
    }),
    ...setMetricDef("commit_time", {
        "label" : "Commit Time",
        "shortLabel" : "Commit Time",
        "key" : "commit_time",
        "field" : "metrics.commit_time.value",
        "unit" : "s",
        "yAxis" : {
            "label" : "Commit Time (seconds)"
        },
        "colors" : {
            "equipment" : config.graph.colors4,
            "environment" : config.graph.colors8,
            "account" : config.graph.colors1
        }
    }),
    ...setMetricDef("connection", {
        "label" : "Connection",
        "shortLabel" : "Connection",
        "key" : "connection",
        "field" : "metrics.connection.value",
        "unit" : "°",
        "yAxis" : {
            "label" : "Connection (°)"
        },
        "colors" : {
            "equipment" : config.graph.colors1,
            "environment" : config.graph.colors8,
            "account" : config.graph.colors5
        }
    }),
    ...setMetricDef("early_connection", {
        "label" : "Early Connection",
        "shortLabel" : "Early Connect",
        "key" : "early_connection",
        "field" : "metrics.early_connection.value",
        "unit" : "°",
        "yAxis" : {
            "label" : "Early Connection (°)"
        },
        "colors" : {
            "equipment" : config.graph.colors7,
            "environment" : config.graph.colors2,
            "account" : config.graph.colors3
        }
    }),
    ...setMetricDef("on_plane", {
        "label" : "On Plane",
        "shortLabel" : "On Plane",
        "key" : "on_plane",
        "field" : "metrics.on_plane.value",
        "unit" : "%",
        "yAxis" : {
            "label" : "On Plane %"
        },
        "colors" : {
            "equipment" : config.graph.colors6,
            "environment" : config.graph.colors3,
            "account" : config.graph.colors5
        }
    }),
    ...setMetricDef("peak_speed", {
        "label" : "Peak Speed",
        "shortLabel" : "Peak Speed",
        "key" : "peak_speed",
        "field" : "metrics.peak_speed.value",
        "unit" : "mph",
        "yAxis" : {
            "label" : "Peak Speed (mph)"
        },
        "colors" : {
            "equipment" : config.graph.colors4,
            "environment" : config.graph.colors6,
            "account" : config.graph.colors1
        }
    }),
    ...setMetricDef("power", {
        "label" : "Power",
        "shortLabel" : "Power",
        "key" : "power",
        "field" : "metrics.power.value",
        "unit" : "",
        "dataView" : true,
        "yAxis" : {
            "label" : "Power"
        },
        "colors" : {
            "equipment" : config.graph.colors3,
            "environment" : config.graph.colors1,
            "account" : config.graph.colors8
        }
    }),
    ...setMetricDef("time_to_contact", {
        "label" : "Time to Contact",
        "shortLabel" : "Contact Time",
        "key" : "time_to_contact",
        "field" : "metrics.time_to_contact.value",
        "unit" : "s",
        "dataView" : true,
        "yAxis" : {
            "label" : "Time to Contact (seconds)"
        },
        "colors" : {
            "equipment" : config.graph.colors7,
            "environment" : config.graph.colors2,
            "account" : config.graph.colors3
        }
    }),
    ...setMetricDef("vertical_bat_angle", {
        "label" : "Vertical Bat Angle",
        "shortLabel" : "Vert Bat (°)",
        "key" : "vertical_bat_angle",
        "field" : "metrics.vertical_bat_angle.value",
        "unit" : "°",
        "dataView" : true,
        "yAxis" : {
            "label" : "Vertical Bat Angle (°)"
        },
        "colors" : {
            "equipment" : config.graph.colors2,
            "environment" : config.graph.colors8,
            "account" : config.graph.colors6
        }
    })
}

export default metrics;

export const metricKeys:string[] = Object.keys(metrics);

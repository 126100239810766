import React, { useState, useEffect, useRef } from 'react';

import './fa/css/all.css';
import './css/App.css';
import './css/Metrics.css';

const useOutsideBlur = (ref:any, hideMenu:Function) => {
    useEffect(() => {
        const handleClickOutside = (event:any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                hideMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

interface ifDropdown {
    options:any[];
    selected:any;
    placeholder?:string;
    emptyPlaceholder?:string;
    setSelected:any;
    className?:string;
}
const Dropdown = ({ options, selected, placeholder, emptyPlaceholder, setSelected, className }:ifDropdown) => {
    const [open, setOpen] = useState<boolean>(false);
    const ddRef = useRef(null);

    useOutsideBlur(ddRef, () => { setOpen(false);});

    return (
        <div className={`dropdown-container ${className}`} onClick={(e) => e.stopPropagation()} ref={ddRef}>
            <div className="dropdown-placeholder" onClick={() => {
                setOpen(open ? false : true);
            }}>
                <span>{placeholder}{(() => {
                    if(!selected) {
                        return emptyPlaceholder ?? "";
                    }
                    return (placeholder ? ": " : "") + options.find((option:any) => option.key == selected).value;
                })()}</span>
                <i className={`fa-solid fa-caret-${open ? "up" : "down"}`} />
            </div>
            <ul className={`${open ? "show" : "hide"}`}>
                {
                    options.map((option:any) => {
                        return <li className={`${selected == option.key ? "selected" : ""}`} key={`key-${option.value}`} onClick={() => {
                            setSelected(option.key);
                            setOpen(false);
                        }}>{option.value}</li>
                    })
                }
            </ul>
        </div>
    );
}

export default Dropdown;
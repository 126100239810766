import React, { useState, useEffect, useRef } from 'react';
import { useAuth0, RedirectLoginOptions } from '@auth0/auth0-react';

const Login = () => {
    const { loginWithRedirect, logout } = useAuth0();

    const handleLogin = () => {
        /*
        loginWithRedirect({
            appState : {
                returnTo : window.location.pathname
            }
        });
        */
        //loginWithRedirect();
        const loginOptions:RedirectLoginOptions = {
            appState : {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: process.env.REACT_APP_AUTH0_AUDIENCE
            }
        }
        loginWithRedirect(loginOptions);
    }

    const handleLogout = () => {
        logout({
            logoutParams : {
                returnTo: window.location.origin
            }
        });
    }

    const handleSignup = () => {
        loginWithRedirect({
            appState: {
                returnTo: window.location.pathname,
            },
            authorizationParams: {
                screen_hint: "signup",
            },
        });
    };

    return <div className="login-container">
        <div className="title-content">
            <h1>Ready to Hit Some Nukes?</h1>
        </div>
        <div className="button-content">
            <button className="login-button" onClick={handleLogin}>
                <i className="fa-solid fa-right-to-bracket" /> Login
            </button>
            <button className="signup-button" onClick={handleSignup}>
                <i className="fa-regular fa-address-card" /> Sign Up
            </button>
            {/*<button className="signup-button" onClick={handleLogout}>
                <i className="fa-regular fa-right-from-bracket" /> Log Out
            </button>*/}
        </div>
    </div>
}

export default Login;

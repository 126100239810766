import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { setAccessToken } from '../reducers/appSlice';
import { access } from 'fs';
import { decode } from 'punycode';

export function useSession() {
    const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();
    const dispatch = useDispatch();
    const accessToken:string = useSelector((state:any) => state.app.accessToken);

    const getAccessToken = async () => {
        try {
            const _token:string = await getAccessTokenSilently({
                authorizationParams : {
                    audience : process.env.REACT_APP_AUTH0_AUDIENCE,
                    scope : process.env.REACT_APP_AUTH0_SCOPE
                }
            });

            // set the access token in global redux state
            if(_token !== accessToken) {
                dispatch(setAccessToken(_token));
            }

            return _token;
        } catch(e) {
            // if an error is thrown fetching an access token, treat it as
            // a token expiration event and redirect the user to the login
            loginRedirect();
        }
    }

    const getAccessTokenForce = () => {
        loginRedirect();
    }

    const loginRedirect = () => {
        loginWithRedirect({
            appState : {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: process.env.REACT_APP_AUTH0_AUDIENCE,
                returnTo: window.location.pathname
            }
        });
    }

    const getUser = () => {
        return user;
    }

    const checkPermission = (access:string) => {
        if(!accessToken) {
            return false;
        }

        const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));

        if(decodedToken && decodedToken.permissions) {
            return decodedToken.permissions.includes(access);
        }

        return false;
    }

    const getTokenClaim = (claim:string) => {
        if(!accessToken) {
            return;
        }

        const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));

        if(decodedToken) {
            return decodedToken[claim];
        }

        return null;
    }

    const getTokenAppClaim = (claim:string) => {
        if(!accessToken) {
            return;
        }

        const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));

        if(decodedToken) {
            return decodedToken[`${process.env.REACT_APP_AUTH0_TOKEN_NAMESPACE}/${claim}`];
        }

        return null;
    }

    return { getAccessToken, getAccessTokenForce, getUser, checkPermission, getTokenClaim, getTokenAppClaim };
}

export default useSession;
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { DateTime } from 'luxon';

import apiRequest from './helper/ws';
import { useSession } from './hooks/session';

import ScatterDate from './ScatterDate';
import DailySplits from './DailySplits';
import SwingStatsByTime from './vzSwingStatsByTime';
import EquipmentByEnvironment from './vzEquipmentByEnvironment';

import './fa/css/all.css';
import './css/App.css';
import './css/Metrics.css';

interface ifEquipmentSplits {
    filters:any;
    visible:boolean;
}
const EquipmentSplits = ({ filters, visible }:ifEquipmentSplits) => {
    const [equipmentList, setEquipmentList] = useState<string[]>([]);
    const [refreshKeyStats, setRefreshKeyStats] = useState<number>(0);
    const [refreshKeyScatter, setRefreshKeyScatter] = useState<number>(0);
    const [refreshKeyDaily, setRefreshKeyDaily] = useState<number>(0);
    const [showCharts, setShowCharts] = useState<boolean>(true);
    const { getAccessToken } = useSession();

    useEffect(() => {
        getData();
    }, [filters]);

    const updateRefreshKeyStats = () => {
        setRefreshKeyStats(refreshKeyStats + 1);
    }

    const updateRefreshKeyScatter = () => {
        setRefreshKeyScatter(refreshKeyScatter + 1);
    }

    const updateRefreshKeyDaily = () => {
        setRefreshKeyDaily(refreshKeyDaily + 1);
    }

    const getData = async () => {
        const swingsResponse = await apiRequest({
            "method" : "GET",
            "path" : `/equipment`,
            "accessToken" : await getAccessToken(),
            "params" : {
                "date_start" : DateTime.fromISO(`${filters.dateStart}T00:00:00`).toUTC().toISO({includeOffset:true}),
                "date_end" : DateTime.fromISO(`${filters.dateEnd}T23:59:59`).toUTC().toISO({includeOffset:true}),
                "account" : filters.account
            }
        });

        setEquipmentList(swingsResponse);
    }

    const EquipmentCharts = ({ equipment }:{equipment:string}) => {
        if(!showCharts) {
            return <></>
        }

        return (
            <>
                <ScatterDate
                    filters={{
                        ...filters,
                        ...{"equipment" : [equipment]}
                    }}
                    visible={visible}
                    viewType="equipment"
                    updateHook={updateRefreshKeyScatter}
                    refreshKey={refreshKeyScatter}
                    subtitle={equipment}
                />
                <DailySplits
                    filters={{
                        ...filters,
                        ...{"equipment" : [equipment]}
                    }}
                    visible={visible}
                    viewType="equipment"
                    updateHook={updateRefreshKeyDaily}
                    refreshKey={refreshKeyDaily}
                    subtitle={equipment}
                />
            </>
        );
    }

    if(!equipmentList.length) {
        return <></>
    }

    return (
        <div className={`route-container ${visible ? "show" : "hide"}`}>
            {
                equipmentList.map((equipment:string) => {
                    return (
                        <div key={`equipment-container-${equipment}`} className="equipment-container">
                            <div className="equipment-header">
                                <div className="equipment-title">
                                    {equipment}
                                </div>
                                <div className="equipment-graph-actions">
                                    <button onClick={() => {
                                        setShowCharts(showCharts ? false : true);
                                    }}><i className={`fa-regular fa-eye${showCharts ? "-slash" : ""}`} /> Charts</button>
                                </div>
                                <div className="bottom" />
                            </div>
                            <SwingStatsByTime
                                key={`equipment-stats-${equipment}`}
                                {...filters}
                                {...{"equipment" : [equipment]}}
                                visible={visible}
                                viewType="equipment"
                                updateHook={updateRefreshKeyStats}
                                refreshKey={refreshKeyStats}
                            />
                            <EquipmentCharts equipment={equipment} />
                        </div>
                    )
                })
            }
            
            <EquipmentByEnvironment key={`equipment-by-environment`} {...filters} visible={true} />
        </div>
    );
}

export default EquipmentSplits;
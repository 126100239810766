import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
    children?: ReactNode;
}

interface State {
    error: boolean;
    errorMessage?: string;
}

class ErrorBoundary extends Component<Props, State> {
    state:State = {
        error: false,
        errorMessage: ""
    }

	static getDerivedStateFromError(error:any) {
		// Update state so the next render will show the fallback UI.
		return {
            error: true,
            errorMessage: error.toString()
        };
	}

	componentDidCatch(error:any, errorInfo:any) {
		// Example "componentStack":
		//   in ComponentThatThrows (created by App)
		//   in ErrorBoundary (created by App)
		//   in div (created by App)
		//   in App
		//logErrorToMyService(error, info.componentStack);
        console.log({ error, errorInfo });
	}

	render() {
        const { error, errorMessage } = this.state;
        const { children } = this.props;

		if(this.state.error) {
            return (
                <div className="error-container">
                    <h1>Oops, something went wrong!</h1>
                </div>
            );
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
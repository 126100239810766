'use strict';

import axios, { AxiosRequestConfig } from 'axios';

import config from './config';

class WebserviceError extends Error {
    private status:number;
    private request:any;
    private response:any;

    constructor(message:Error, status:number, request:any, response:any) {
        super(message.toString());

        this.message = message.toString();
        this.status = status;
        this.request = request;
        this.response = response;
    }
}

const API_HOST = config.api.host ? `${config.api.ssl ? "https://" : "http://"}${config.api.host}` : "";
const API_ROOT = config.api.root;

interface ifCall {
    data?:any;
    path:string;
    method:string;
    params?:any;
    headers?:any;
    includeMeta?:boolean;
    accessToken?:string;
}
export default async function(options:ifCall) {
    const apiRoot = config.api.root;

    const headers:any = {
        "Accept" : "application/json"
    };

    if(options.data) {
        headers['Content-Type'] = "application/json";
    }

    if(options.accessToken) {
        headers['Authorization'] = `Bearer ${options.accessToken}`;
    }

    let axConfig:AxiosRequestConfig = {
        "method" : options.method,
        "url" : API_HOST + apiRoot + options.path,
        "headers" : headers,
        "params" : options.params,
        "data" : options.data ? JSON.stringify(options.data) : null,
        "responseType" : "json"
    }

    if(options.headers) {
        for(let key in options.headers) {
            axConfig.headers![key] = options.headers[key];
        }
    }

    try {
        const response = await axios(axConfig);
        
        if(options.includeMeta) {
            return response.data;
        } else {
            return response.data.response;
        }
    } catch(e:any) {
        throw new WebserviceError(e, 500, axConfig, null);
    }
}


export const getEndpoint = (uri:string) => {
    return API_HOST + API_ROOT + uri;
}


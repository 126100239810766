
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { DateTime } from 'luxon';

import apiRequest from './helper/ws';
import config from './helper/config';
import { useSession } from './hooks/session';

HighchartsMore(Highcharts);

interface ifEquipmentByEnvironment {
    dateStart:string|null;
    dateEnd:string|null;
    equipment:string[];
    environment:string[];
    account?:any;
    selected:boolean;
    visible:boolean;
}
const EquipmentByEnvironment = ({ dateStart, dateEnd, equipment, environment, account, selected, visible }:ifEquipmentByEnvironment) => {
    const [swingData, setSwingData] = useState<any>(null);
    const [swingDataLoading, setSwingDataLoading] = useState<boolean>(true);
    const accounts:any[] = useSelector((state:any) => state.app.accountList);
    const selectedAccount:any = useSelector((state:any) => state.app.selectedAccount);
    const { getAccessToken } = useSession();
    const highchartsRef = useRef(null);
    const defaultFields = [
        "context.environment",
        "equipment.name"
    ];
    const aggTypes = [
        "count",
        "avg",
        "max",
        "min"
    ];

    useEffect(() => {
        // ensure required filter values are set
        if(dateStart && dateEnd && visible) {
            setSwingDataLoading(true);
            getData();
        }
    }, [
        dateStart,
        dateEnd,
        JSON.stringify(equipment), 
        JSON.stringify(environment.length),
        JSON.stringify(account),
        visible
    ]);

    useEffect(() => {
        if(swingData) {
            renderChart();
        }
    }, [swingData]);

    const getData = async () => {
        const swingsResponse = await apiRequest({
            "method" : "GET",
            "path" : `/swings/stats/equipment`,
            "accessToken" : await getAccessToken(),
            "params" : {
                "date_start" : DateTime.fromISO(`${dateStart}T00:00:00`).toUTC().toISO({includeOffset:true}),
                "date_end" : DateTime.fromISO(`${dateEnd}T23:59:59`).toUTC().toISO({includeOffset:true}),
                "equipment" : equipment.join(","),
                "environment" : environment.join(","),
                "account" : account,
                "fields" : defaultFields
            }
        });

        // set the swingData and also create an incrementing value for each (x axis)
        setSwingData(swingsResponse);
    }

    const renderChart = ():void => {
        const chartData:Highcharts.Options = {
            chart: {
                type: 'column',
                inverted: true,
                polar: true
            },
            colors: config.graph.colors1,
            title: {
                text: `Bats by Environment Splits`,
                align: 'left'
            },
            legend: {
                enabled: true
            },
            xAxis: {
                tickInterval: 1,
                labels: {
                    align: 'right',
                    useHTML: true,
                    allowOverlap: true,
                    step: 1,
                    y: 3,
                    style: {
                        fontSize: '13px',
                        color: 'var(--color-font-500)'
                    }
                },
                lineWidth: 0,
                gridLineWidth: 0,
                categories: swingData.map((equipBucket:any) => {
                    return equipBucket.equipment;
                })
            },
            yAxis: {
                lineWidth: 0,
                tickInterval: Math.round(Math.max(...swingData.map((equipment:any) => {
                    return Math.max(...equipment.environments.map((env:any) => {
                        return env.count;
                    }));
                })) / 15),
                reversedStacks: false,
                endOnTick: true,
                showLastLabel: true,
                gridLineWidth: 0,
                labels: {
                    formatter: function () {
                        return this.value.toLocaleString();
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    borderWidth: 0,
                    pointPadding: 0,
                    groupPadding: 0.15
                },
                series: {
                    animation: false
                }
            },
            tooltip: {
                formatter: function() {
                    let tooltip:string = `Bat: <b>${this.key}</b><br />`;
                    tooltip += `Environment: <b>${this.point.series.name}</b><br />`;
                    tooltip += `Total Swings: <b>${Number(this.y).toLocaleString()}</b><br />`
                    return tooltip;
                }
            },
            pane: {
                size: '85%',
                innerSize: '20%',
                endAngle: 270
            },
            series: [
                ...getSeries()
            ],
            credits: {
                enabled: false
            },
            accessibility : {
                enabled: false
            }
        }

        Highcharts.chart(highchartsRef.current!, chartData);
    }

    

    const getSeries = ():any[] => {
        const _series:any[] = [];
        const _environments:string[] = [];

        swingData.forEach((equipment:any) => {
            equipment.environments.forEach((environment:any) => {
                if(!_environments.includes(environment.environment)) {
                    _environments.push(environment.environment);
                }
            });
        });

        _environments.forEach((environment:string) => {
            const _environmentSeries:number[] = [];
            swingData.forEach((equipment:any) => {
                const _envByEquip:any = equipment.environments.find((env:any) => env.environment === environment);

                if(_envByEquip) {
                    _environmentSeries.push(_envByEquip.count);
                } else {
                    _environmentSeries.push(0);
                }
            });

            _series.push({
                name: environment,
                data: _environmentSeries
            });
        });

        return _series;
    }

    if(!swingData) {
        return (
            <div className={`graph-container ${!selected ? "hide" : ""}`}>
                <div className={`graph-loader`} />
            </div>
        );
    }

    return (
        <div className={`graph-container small ${!selected ? "hide" : ""}`}>
            <div ref={highchartsRef}></div>
            <div className={`graph-loader ${swingDataLoading ? "show" : "hide"}`} />
        </div>
    );
}


export default EquipmentByEnvironment;
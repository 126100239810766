import React, { useEffect } from 'react';

export const useOutsideBlur = (ref:any, hideMenu:Function) => {
    useEffect(() => {
        const handleClickOutside = (event:any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                hideMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const getNestedValue = (nestedObject:any, field:string) => {
    // Traverse the object using the keys
    return field.split('.').reduce((obj, key) => {
        return obj ? obj[key] : undefined;
    }, nestedObject);
}


export const formatNumber = (value:number):string => {
    if(!value) {
        return "0";
    }

    if(value % 1 === 0) {
        return value.toString();
    }

    return value.toFixed(2);
}
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import DatePicker from "react-datepicker";
import { DateTime } from 'luxon';

import apiRequest from './helper/ws';
import { useOutsideBlur } from './helper/utils';
//import metrics, { metricKeys } from './metricDefinitions';
import { useSession } from './hooks/session';

import Login from './Login';
import AccountLink from './AccountLink';
import ErrorBoundry from './ErrorBoundry';
import Header from './Header';
import ScatterDate from './ScatterDate';
import ScatterDual from './ScatterDual';
import DailySplits from './DailySplits';
import EquipmentSplits from './EquipmentSplits';
import Distribution from './Distribution';
import SwingsList from './SwingsList';
import SwingStatsByTime from './vzSwingStatsByTime';
import { setAccountList, setSelectedAccount } from './reducers/appSlice';

import "react-datepicker/dist/react-datepicker.css";
import './fa/css/all.css';
import './css/App.css';
import './css/Metrics.css';
import ws from './helper/ws';
import { access } from 'fs';
import { isArray } from 'highcharts';

const NotFound = () => {
    return (
        <div className="not-found">
            O Noes!  The Route you attempted to reach could not be found...
        </div>
    );
}

interface ifEquipmentFilter {
    equipmentMenuRef:any|null;
    equipmentList:string[];
    filters:any;
    selectEquipment:any;
    route:string;
}
const EquipmentFilter = ({ equipmentMenuRef, equipmentList, filters, selectEquipment, route }:ifEquipmentFilter) => {
    const [equipmentMenuOpen, setEquipmentMenuOpen] = useState<boolean>(false);
    const selectedLabel:string = (() => {
        if(filters.equipment.length < 1) {
            return "All Bats";
        } else if(filters.equipment.length === 1) {
            return filters.equipment[0];
        } else {
            return `Multiple Bats (${filters.equipment.length})`;
        }
    })();

    const iconClass = (selected:boolean) => {
        if(filters.equipment.length) {
            return `fa-regular fa-square${selected ? "-check" : ""}`;
        } else {
            return `fa-regular fa-square-minus : ""}`;
        }
    }

    const handleClick = (equipment:string) => {
        selectEquipment(equipment);
    }

    const handleIconClick = (equipment:string) => {
        if(!filters.equipment.length) {
            selectEquipment(equipmentList.filter((equipmentItem:string) => equipmentItem !== equipment));
        } else {
            selectEquipment(equipment);
        }
    }

    useOutsideBlur(equipmentMenuRef, () => { setEquipmentMenuOpen(false);});

    if(route === "equipment-splits") {
        return <></>
    }

    return (
        <div
            className={`filter-dropdown ${equipmentMenuOpen ? "open" : ""}`}
            id="equipment"
            onClick={(e) => {
                setEquipmentMenuOpen(equipmentMenuOpen ? false : true);
                e.stopPropagation();
            }}
            ref={equipmentMenuRef}
        >
            <div className="width-set">{equipmentList.map((equipment:string) => { return <li key={"w-" + equipment}>{equipment}<i className="caret fa-solid fa-caret-down" /></li>; })}</div>
            <div className="dropdown-display">
                <span className="filter-selected">{selectedLabel}</span>
                <i className={`caret fa-solid fa-caret-${equipmentMenuOpen ? "up" : "down"}`} />
            </div>
            <ul className={`${equipmentMenuOpen ? "show" : "hide"}`}>
                <li className={`${!filters.equipment.length ? "selected" : ""}`} onClick={(event) => {
                    selectEquipment("");
                    event.stopPropagation();
                }}>All Bats</li>
                {
                    equipmentList.map((equipment:string) => {
                        const selected:boolean = filters.equipment.includes(equipment);
                        return <li key={equipment} className={`${selected ? "selected" : ""}`} onClick={(event) => {
                            handleClick(equipment);
                            event.stopPropagation();
                        }}><i onClick={(event) => {
                            handleIconClick(equipment);
                            event.stopPropagation();
                        }} className={iconClass(selected)} /> {equipment}</li>
                    })
                }
            </ul>
        </div>
    );
}


interface ifEnvironmentFilter {
    environmentMenuRef:any|null;
    environmentList:string[];
    filters:any;
    selectEnvironment:any;
}
const EnvironmentFilter = ({ environmentMenuRef, environmentList, filters, selectEnvironment }:ifEnvironmentFilter) => {
    const [environmentMenuOpen, setEnvironmentMenuOpen] = useState<boolean>(false);
    const selectedLabel:string = (() => {
        if(filters.environment.length < 1) {
            return "All Environments";
        } else if(filters.environment.length === 1) {
            return filters.environment[0];
        } else {
            return `Multiple Environments (${filters.environment.length})`;
        }
    })();

    const iconClass = (selected:boolean) => {
        if(filters.environment.length) {
            return `fa-regular fa-square${selected ? "-check" : ""}`;
        } else {
            return `fa-regular fa-square-minus : ""}`;
        }
    }

    const handleClick = (environment:string) => {
        selectEnvironment(environment);
    }

    const handleIconClick = (environment:string) => {
        if(!filters.environment.length) {
            selectEnvironment(environmentList.filter((environmentItem:string) => environmentItem !== environment));
        } else {
            selectEnvironment(environment);
        }
    }

    useOutsideBlur(environmentMenuRef, () => { setEnvironmentMenuOpen(false);});

    return (
        <div
            className={`filter-dropdown ${environmentMenuOpen ? "open" : ""}`}
            id="environment"
            onClick={(e) => {
                setEnvironmentMenuOpen(environmentMenuOpen ? false : true);
                e.stopPropagation();
            }}
            ref={environmentMenuRef}
        >
            <div className="width-set">{environmentList.map((environment:string) => { return <li key={"w-" + environment}>{environment}<i className="caret fa-solid fa-caret-down" /></li>; })}</div>
            <div className="dropdown-display">
                <span className="filter-selected">{selectedLabel}</span>
                <i className={`caret fa-solid fa-caret-${environmentMenuOpen ? "up" : "down"}`} />
            </div>
            <ul className={`${environmentMenuOpen ? "show" : "hide"}`}>
            <li className={`${!filters.environment.length ? "selected" : ""}`} onClick={(event) => {
                    handleClick("");
                    event.stopPropagation();
                }}>All Environments</li>
                {
                    environmentList.map((environment:string) => {
                        const selected:boolean = filters.environment.includes(environment);
                        return <li key={environment} className={`${selected ? "selected" : ""}`} onClick={(event) => {
                            handleClick(environment);
                            event.stopPropagation();
                        }}><i onClick={(event) => {
                            handleIconClick(environment);
                            event.stopPropagation();
                        }} className={iconClass(selected)} /> {environment}</li>
                    })
                }
            </ul>
        </div>
    );
}

interface ifDateFilter {
    dateMin:string|null;
    dateMax:string|null;
    startDate:string|null;
    endDate:string|null;
    setFilters:Function;
}
const DateFilter = ({ dateMin, dateMax, startDate, endDate, setFilters }:ifDateFilter) => {
    const [dateStart, setDateStart] = useState<string|null>(startDate);
    const [dateEnd, setDateEnd] = useState<string|null>(endDate);

    useEffect(() => {
        setDateStart(startDate);
        setDateEnd(endDate);
    }, [startDate, endDate]);

    return (
        <div className="datepicker-container datepicker-container-start">
            <DatePicker
                onChange={(dates:any[]) => {
                    setDateStart(dates[0]? DateTime.fromJSDate(dates[0]).toFormat("yyyy-MM-dd") : null);
                    setDateEnd(dates[1] ? DateTime.fromJSDate(dates[1]).toFormat("yyyy-MM-dd") : null);

                    if(dates[0] && dates[1]) {
                        setFilters(dates[0], dates[1]);
                    }
                }}
                minDate={dateMin ? DateTime.fromISO(dateMin).toJSDate() : null}
                maxDate={dateMax ? DateTime.fromISO(dateMax).toJSDate() : null}
                startDate={dateStart ? DateTime.fromISO(dateStart).toJSDate() : null}
                endDate={dateEnd ? DateTime.fromISO(dateEnd).toJSDate() : null}
                selectsRange={true}
                wrapperClassName="filter-datepicker"
                calendarClassName="calendar-picker"
            />
        </div>
    )
}


interface ifAccountFilter {
    accountMenuRef:any|null;
    filters:any;
    selectAccount:Function;
}
const AccountFilter = ({ accountMenuRef, filters, selectAccount }:ifAccountFilter) => {
    const selectedAccount:any = useSelector((state:any) => state.app.selectedAccount) ?? {};
    const accountList:any[] = useSelector((state:any) => state.app.accountList) ?? [];
    const [accountsMenuOpen, setAccountsMenuOpen] = useState<boolean>(false);
    const selectedLabel:string = (() => {
        if(filters.account.length < 1) {
            return "All Players";
        } else if(filters.account.length === 1) {
            if(!filters.account[0]) {
                return "All Players";
            } else {
                return accountList.find((account:any) => account.blast_id === filters.account[0]).name;
            }
        } else {
            return `Multiple Players (${filters.account.length})`;
        }
    })();

    const iconClass = (selected:boolean) => {
        if(filters.account.length) {
            return `fa-regular fa-square${selected ? "-check" : ""}`;
        } else {
            return `fa-regular fa-square-minus : ""}`;
        }
    }

    const handleClick = (account:number) => {
        selectAccount(account);
    }

    const handleIconClick = (account:number) => {
        if(!filters.account.length) {
            selectAccount(accountList.filter((accountItem:any) => accountItem.blast_id !== account).map((accountItem:any) => {
                return accountItem.blast_id;
            }));
        } else {
            selectAccount(account);
        }
    }

    useOutsideBlur(accountMenuRef, () => { setAccountsMenuOpen(false);});

    if(selectedAccount.blast_id || accountList.length < 2) {
        return <></>
    }

    return (
        <div
            className={`filter-dropdown ${accountsMenuOpen ? "open" : ""}`}
            id="equipment"
            onClick={(e) => {
                setAccountsMenuOpen(accountsMenuOpen ? false : true);
                e.stopPropagation();
            }}
            ref={accountMenuRef}
        >
            <div className="width-set">{accountList.map((account:any) => { return <li key={"w-" + account.id}>{account.name}<i className="caret fa-solid fa-caret-down" /></li>; })}</div>
            <div className="dropdown-display">
                <span className="filter-selected">{selectedLabel}</span>
                <i className={`caret fa-solid fa-caret-${accountsMenuOpen ? "up" : "down"}`} />
            </div>
            <ul className={`${accountsMenuOpen ? "show" : "hide"}`}>
                <li className={`${!filters.account.length ? "selected" : ""}`} onClick={(event) => {
                    handleClick(0);
                    event.stopPropagation();
                }}>All Players</li>
                {
                    accountList.map((account:any) => {
                        const selected:boolean = filters.account.includes(account.blast_id);
                        return <li key={account.blast_id} className={`${selected ? "selected" : ""}`} onClick={(event) => {
                            handleClick(account.blast_id);
                            event.stopPropagation();
                        }}><i onClick={(event) => {
                           handleIconClick(account.blast_id);
                           event.stopPropagation(); 
                        }} className={iconClass(selected)} /> {account.name}</li>
                    })
                }
            </ul>
        </div>
    );
}


interface ifGraphs {
    route:string;
}
const Graphs = ({ route }:ifGraphs) => {
    interface ifFirstLastDates {
        dateFirst:string|null;
        dateLast:string|null;
    }
    interface ifFilters {
        dateStart:string|null;
        dateEnd:string|null;
        equipment:string[];
        environment:string[];
        account?:number[]|null;
    }
    const selectedAccount:any = useSelector((state:any) => state.app.selectedAccount) ?? {};
    //const [selectedAccount, setSelectedAccount] = useState<any>({});
    const [selectedAccountProfile, setSelectedAccountProfile] = useState<any>({});
    const [firstLastDates, setFirstLastDates] = useState<ifFirstLastDates>({
        "dateFirst" : null,
        "dateLast" : null
    });
    const [filters, setFilters] = useState<ifFilters>({
        "dateStart" : null,
        "dateEnd" : null,
        "equipment" : [],
        "environment" : [],
        "account" : selectedAccount.blast_id ? [ selectedAccount.blast_id ] : []
    });
    const [equipmentList, setEquipmentList] = useState<string[]>([]);
    const [environmentList, setEnvironmentList] = useState<string[]>([]);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const equipmentMenuRef = useRef(null);
    const environmentMenuRef = useRef(null);
    const accountMenuRef = useRef(null);
    const { getAccessToken } = useSession();
    const accounts:any[] = useSelector((state:any) => state.app.accountList);

    //useEffect(() => {
    //    console.log(filters);
    //}, [filters]);

    useEffect(() => {
        getFirstLastDates();
        getEquipmentList();
        getEnvironmentList();

        if(selectedAccount.blast_id) {
            getAccountProfile(selectedAccount.blast_id);
        }
    }, [refreshKey]);

    useEffect(() => {
        setFilters({
            ...filters,
            "equipment" : [],
            "environment" : [],
            "account" : selectedAccount.blast_id ? [ selectedAccount.blast_id ] : []
        });

        setRefreshKey(refreshKey + 1);
    }, [JSON.stringify(selectedAccount)]);

    useEffect(() => {
        if(firstLastDates.dateFirst && firstLastDates.dateLast) {
            setFilters({
                ...filters,
                ...{
                    "dateStart" : DateTime.fromISO(firstLastDates.dateLast).minus({ days: 7 }).toFormat("yyyy-MM-dd"),
                    "dateEnd" : DateTime.fromISO(firstLastDates.dateLast).toFormat("yyyy-MM-dd")
                }
            });
        }
    }, [JSON.stringify(firstLastDates)]);

    const getAccountProfile = async (accountId:number) => {
        const _account = await apiRequest({
            "path" : `/accounts/${accountId}`,
            "method" : "GET",
            "accessToken" : await getAccessToken()
        });
        setSelectedAccountProfile(_account);
    }

    const getFirstLastDates = async () => {
        const datesResponse = await apiRequest({
            "path" : "/swings/dates/first-last",
            "method" : "GET",
            "params" : {
                "account" : [selectedAccount.blast_id ?? filters.account]
            },
            "accessToken" : await getAccessToken()
        });

        setFirstLastDates({
            "dateFirst" : datesResponse.first_date,
            "dateLast" : datesResponse.last_date
        });
    }

    const getEquipmentList = async () => {
        const equipmentResponse = await apiRequest({
            "path" : "/equipment",
            "method" : "GET",
            "params" : {
                "account" : [selectedAccount.blast_id]
            },
            "accessToken" : await getAccessToken()
        });

        setEquipmentList(equipmentResponse);
    }

    const getEnvironmentList = async () => {
        const environmentsResponse = await apiRequest({
            "path" : "/environments",
            "method" : "GET",
            "params" : {
                "account" : [selectedAccount.blast_id]
            },
            "accessToken" : await getAccessToken()
        });

        setEnvironmentList(environmentsResponse);
    }

    const selectEquipment = (equipment:string|string[]) => {
        const selectedEquipment:string[] = filters.equipment ?? [];

        if(Array.isArray(equipment)) {
            setFilters({
                ...filters,
                ...{
                    "equipment" : equipment
                }
            });

            return;
        }

        const equipmentIndex:number = selectedEquipment.indexOf(equipment);

        if(!equipment) {
            selectedEquipment.splice(0, selectedEquipment.length);
        } else if(equipmentIndex === -1) {
            selectedEquipment.push(equipment);
        } else {
            selectedEquipment.splice(equipmentIndex, 1);
        }

        // clear the array if all equipment has been selected
        if(selectedEquipment.length === equipmentList.length) {
            selectedEquipment.splice(0, selectedEquipment.length);
        }

        setFilters({
            ...filters,
            ...{
                "equipment" : selectedEquipment
            }
        });
    }

    const selectEnvironment = (environment:string|string[]) => {
        const selectedEnvironment:string[] = filters.environment ?? [];

        if(Array.isArray(environment)) {
            setFilters({
                ...filters,
                ...{
                    "environment" : environment
                }
            });

            return;
        }

        const environmentIndex:number = selectedEnvironment.indexOf(environment);

        if(!environment) {
            selectedEnvironment.splice(0, selectedEnvironment.length);
        } else if(environmentIndex === -1) {
            selectedEnvironment.push(environment);
        } else {
            selectedEnvironment.splice(environmentIndex, 1);
        }

        // clear the array if all environments have been selected
        if(selectedEnvironment.length === environmentList.length) {
            selectedEnvironment.splice(0, selectedEnvironment.length);
        }

        setFilters({
            ...filters,
            ...{
                "environment" : selectedEnvironment
            }
        });
    }

    const selectAccount = (accountId:number|number[]) => {
        const selectedAccount:number[] = filters.account ?? [];

        if(Array.isArray(accountId)) {
            setFilters({
                ...filters,
                ...{
                    "account" : accountId
                }
            });

            return;
        }

        const accountIndex:number = selectedAccount.indexOf(accountId);

        if(!accountId) {
            selectedAccount.splice(0, selectedAccount.length);
        } else if(accountIndex === -1) {
            selectedAccount.push(accountId);
        } else {
            selectedAccount.splice(accountIndex, 1);
        }

        // clear the array if all environments have been selected
        if(selectedAccount.length === accounts.length) {
            selectedAccount.splice(0, selectedAccount.length);
        }

        setFilters({
            ...filters,
            ...{
                "account" : selectedAccount
            }
        });
    }

    return (
        <div id={selectedAccount.blast_id}>
            <Header
                route={route}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                selectedAccountProfile={selectedAccountProfile}
                refresh={() => {
                    setRefreshKey(refreshKey + 1);
                }}
            />
            <div className="main-content-container">
                <div className="metrics">
                    <div className="filter-container">
                        <DateFilter
                            dateMin={firstLastDates.dateFirst}
                            dateMax={firstLastDates.dateLast}
                            startDate={filters.dateStart}
                            endDate={filters.dateEnd}
                            setFilters={(start:Date, end:Date) => {
                                setFilters({
                                    ...filters,
                                    ...{
                                        "dateStart" : start ? DateTime.fromJSDate(start).toFormat("yyyy-MM-dd") : null,
                                        "dateEnd" : end ? DateTime.fromJSDate(end).toFormat("yyyy-MM-dd") : null
                                    }
                                });
                            }}    
                        />
                        <EquipmentFilter equipmentMenuRef={equipmentMenuRef} equipmentList={equipmentList} filters={filters} selectEquipment={selectEquipment} route={route} />
                        <EnvironmentFilter environmentMenuRef={environmentMenuRef} environmentList={environmentList} filters={filters} selectEnvironment={selectEnvironment} />
                        <AccountFilter accountMenuRef={accountMenuRef} filters={filters} selectAccount={selectAccount} />
                    </div>

                    <SwingStatsByTime {...filters} visible={route !== "equipment-splits"} viewType="global" />
                    <ScatterDate filters={filters} visible={route === "time-series"} viewType="scatter" />
                    <ScatterDual filters={filters} visible={route === "dual-series"} />
                    <DailySplits filters={filters} visible={route === "daily-splits"} />
                    <EquipmentSplits filters={filters} visible={route === "equipment-splits"} />
                    <Distribution filters={filters} visible={route === "distribution"} />
                    <SwingsList {...filters} visible={route === "swing-data"} equipmentList={equipmentList} environmentList={environmentList} />
                </div>
            </div>
        </div>
    );
}


const App = () => {
    //const [themeColor, setThemeColor] = useState(null);
    const { isAuthenticated, isLoading } = useAuth0();
    const { getAccessToken } = useSession();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accounts:any[] = useSelector((state:any) => state.app.accountList);

    useEffect(() => {
        if(!accounts.length && !isLoading && isAuthenticated) {
            (async () => {
                const accountsResponse:any = await apiRequest({
                    "path" : "/user/accounts",
                    "method" : "GET",
                    "accessToken" : await getAccessToken()
                });

                dispatch(setAccountList(accountsResponse));

                if(!accountsResponse.length) {
                    navigate("/link");
                }
            })();
        }
    }, [isAuthenticated, isLoading]);

    if(isLoading) { // the auth0 sdk has not fully loaded
        return <div className={`app-loader`}>
            <div className="loader-container">
                <div className="loading-text">Please Wait</div>
                <i className="fa-solid fa-spinner rotate" />
            </div>
        </div>
    } else if(!isAuthenticated) {
        return <Login />
    } else if(isAuthenticated) {
        return <ErrorBoundry>
            <Routes>
                <Route path="/" element={<Graphs route="time-series" />} />
                <Route path="/time-series" element={<Graphs route="time-series" />} />
                <Route path="/dual-series" element={<Graphs route="dual-series" />} />
                <Route path="/daily-splits" element={<Graphs route="daily-splits" />} />
                <Route path="/equipment-splits" element={<Graphs route="equipment-splits" />} />
                <Route path="/distribution" element={<Graphs route="distribution" />} />
                <Route path="/swing-data" element={<Graphs route="swing-data" />} />
                <Route path="/link" element={<AccountLink />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </ErrorBoundry>
    } else {
        return <>Uh oh, you found an unknown application state</>
    }
}

export default App;

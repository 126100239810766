import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/index';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './Auth0ProviderWithNavigate';
import App from './App';

import app from './helper/app';
import ws from './helper/ws';
import config from './helper/config';

import './index.css';

//import reportWebVitals from './reportWebVitals';

// FOR PRODUCTION, ADD <React.StrictMode> at the start/end of the render block
// <React.StrictMode> was removed because of double API calls
const root = createRoot(document.getElementById('root')!);

const main = () => {
    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <Auth0ProviderWithNavigate>
                    <App />
                </Auth0ProviderWithNavigate>
            </BrowserRouter>
        </Provider>
    );
}


const init = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    main();
}


init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
